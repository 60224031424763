<template>
  <div class="g_page_box white">
    <div class="g_main_content">
      <div class="noCard">
        <img src="../../assets/img/noCard.png" alt="" width="185px" />
        <p>暂未发现健康卡，请前往医院线下办理</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},

    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    // 控制退出公众号
    window.history.pushState(null, null)
    window.addEventListener('popstate', this.popstate, false)
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    popstate (e) {
      //qiankun 微服务内部api，更新可能引起未知bug
      if(e.singleSpaTrigger === "pushState" || e.singleSpaTrigger === "replaceState"){
        return
      }
      wx.closeWindow() // 微信网页退出
    }
  },
};
</script>

<style scoped>
.g_page_box {
  &.white {
    background: #ffffff;
  }
}

.noCard {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    text-align: center;
    width: 189px;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: #242938;
    margin-top: 35px;
  }
}


</style>
